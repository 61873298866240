(function() {
    'use strict';

    angular
        .module('pruGaGreatApp', [
            'corePRUGAGreatApp'
        ])
        .run(run);

    run.$inject = ['stateHandler'];

    function run(stateHandler) {
        stateHandler.initialize();
    }
})();

/*
 .config(['$logProvider', function($logProvider){
    $logProvider.debugEnabled(false);
}])
 */
